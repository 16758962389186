@import '_colors.scss';
@import '_vars.scss';

.Background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;

  transition: $transition;
  
  &.none {
    background-color: $bg_none;
  }

  &.negative {
    background-color: $bg_negative;
  }

  &.positive {
    background-color: $bg_positive;
  }
}